import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/wcenatus/Documents/GitHub/cwa-site/src/templates/MdxLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`TSYS `}<a parentName="p" {...{
        "href": "http://www.marketwatch.com/investing/stock/tss?mod=MW_story_quote"
      }}>{`TSS, `}{`-`}{`1.12%`}</a>{` a leading global payments provider, and Ingenico Group , a global leader in seamless payments, today announced a new semi-integrated solution to simplify the EMV `}{`[`}{`®`}{`]`}{` certification process. TSYS is the first processor to Class A certify Ingenico Group’s new offering, which is designed to enable partners with the ability to easily build and deploy secure EMV and NFC solutions.`}</p>
    <p>{`The combined TSYS and Ingenico Group offering provides Value-Added Resellers (VARs) and Integrated Software Vendors (ISVs) with a simple and secure pathway to begin enabling EMV payments. This easy-to-implement solution empowers VARs and ISVs to focus on developing their core products, while offering them a secure method for accepting payments, including magnetic stripe, EMV and NFC contactless payments.`}</p>
    <p>{`Using a semi-integrated approach, communications between the PIN pad and point-of-sale (POS) system are limited to non-sensitive exchanges, preventing card data from entering the POS. By taking the POS out of the payment flow process, not only are EMV certification and PCI compliance simplified, but overall costs and time required for EMV implementation are reduced as well. Fully certified through all major card brands, this new TSYS-certified solution also has the ability to process with TSYS Guardian Encryption `}{`[`}{`SM`}{`]`}{` , ensuring payments are both reliable and secure.`}</p>
    <p>{`For the rest of this article, head to `}<a parentName="p" {...{
        "href": "http://www.marketwatch.com/story/tsys-and-ingenico-group-to-offer-new-semi-integrated-emv-solution-2015-10-26"
      }}>{`MARKETWATCH`}</a>{`.`}</p>
    <p>{`For any further questions about the exciting advances with Ingenico products, please contact CWA merchant support:`}</p>
    <p>{`(866) 210-4625 X1`}</p>
    <p><a parentName="p" {...{
        "href": "mailto:merchantsupport@cwams.com"
      }}>{`merchantsupport@cwams.com`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      